import React, { ChangeEvent, useState } from 'react';
import { levels } from './data/levels'
import { calculateGold } from './utils/calculate-gold';
import { calculateExperience } from './utils/calculate-experience';
import { getExperienceToBeGained } from './utils/get-experience-to-be-gained';
import { getLevel } from './utils/get-level';
import logoText from './images/logo-text.png';
import logoTextWeb from './images/logo-text.webp';
import logoImg from './images/logo-img.png';
import logoImgWeb from './images/logo-img.webp';
import { calculateRewards, Reward } from './utils/calculate-rewards';
import { FreeRewardCategory, FreeRewardType } from './data/freeRewards';
import './App.css';

function App() {
  const [info, setInfo] = useState(null as any);
  const maxExperience = 603700;
  const rewards = calculateRewards(info?.lvl || 0, levels);
  const intl = new Intl.NumberFormat('ru-RU');

  // TODO: Вычисление середины недели вынести в функцию
  const dayOfWeek = (new Date().getDay() || 7) - 1;
  const r = (dayOfWeek - 3.5) * 1000*60*60*24;
  const midweek = new Date().setHours(0, 0, 0, 0) - r;
  // End TODO
  const timePassed = midweek - Date.parse('November 17, 2020 21:00:00');
  const timeLeft = Date.parse('March 22, 2021 21:00:00') - midweek;
  const timeIsOut = Boolean(timeLeft < 0);

  const renderRewards = (rewards: Reward[]) => (
    <div className="rewards">
      {rewards.map(reward => (
        <div key={reward.id} className="reward">
          {reward.count > 1 && ( 
            reward.type === FreeRewardType.CardsSet ? (
            <span className="reward__count reward__count_flag">{reward.count}</span>
          ) : (
            <span className="reward__count">x{reward.count}</span>
          ))}
          <img className="reward__img" src={reward.imgUrl} alt={reward.name}/>
          <span className="reward__name">{reward.name}</span>
        </div>
      ))}
    </div>
  );

  const getAllInformation = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.currentTarget;
    if (input.value && !isNaN(Number(input.value))) {
      const currentLvl = Number(input.value);
      const experienceGained = calculateExperience(currentLvl, levels)
      const experienceToBeGained = getExperienceToBeGained(experienceGained, timePassed, timeLeft);
      const gold = calculateGold(currentLvl, levels);
      const info: any = {
        timeIsOut,
        gold,
        experienceGained,
        lvl: currentLvl,
        levelsLeft: levels.length - currentLvl,
        experienceLeft: maxExperience - experienceGained,
      };
      // Если дополнение еще не вышло
      if (!timeIsOut) {
        const possibleLevel = getLevel(levels, experienceGained + experienceToBeGained);
        info.possibleLevel = possibleLevel;
        info.possibleGold = (calculateGold(possibleLevel, levels) - gold)
      }
      setInfo(info);
    }
  }
  
  return (
    <div className="hs-exp">
      <header className="header">
        <div className="logo-line" />
        <div className="header__images">
          <picture> 
            <source srcSet={logoTextWeb} type="image/webp" />
            <source srcSet={logoText} type="image/png" /> 
            <img className="header__logo header__logo_text" src={logoText} alt="Hearthstone Experience" />
          </picture>
          <picture> 
            <source srcSet={logoImgWeb} type="image/webp" />
            <source srcSet={logoImg} type="image/png" /> 
            <img className="header__logo header__logo_img" src={logoImg} alt="Hearthstone Лента наград" />
          </picture>
        </div>
        <h1 className="header__title">Подсчитаем твою прибыль от ленты наград!</h1>
      </header>
      <section className="lvl">
        <h2 className="lvl__title">Просто введи текущий уровень в Hearthstone:</h2>
        <form className="lvl__form">
          <input className="lvl__input" onInput={getAllInformation} autoFocus maxLength={3} minLength={1} />
        </form>
      </section>

      {info && (info.lvl < 350 || info.lvl > 0) && (
        <div className="info">
          <div className="info__row">
            <div className="info__cell">Заработанное золото</div>
            <div className="info__cell"> {intl.format(info.gold)}</div>
          </div>
          {!info.timeIsOut && (
            <>
              <div className="info__row">
                <div className="info__cell">Возможный уровень в конце дополнения</div>
                <div className="info__cell"> {info.possibleLevel}</div>
              </div>
              <div className="info__row">
                <div className="info__cell">Золото полученное с {info.lvl} уровня по {info.possibleLevel}</div>
                <div className="info__cell"> {intl.format(info.possibleGold)}</div>
              </div>
              <div className="info__row">
                <div className="info__cell">Золото полученное за все время дополнения</div>
                <div className="info__cell"> {intl.format(info.possibleGold + info.gold)}</div>
              </div>
            </>
          )}
        </div>
      )}

      {rewards && rewards[FreeRewardCategory.CardsSet] && (
        <section>
          <div className="free-rewards">
            <div className="rewards__category">
              <h3 className="rewards__category-title">
                Полученные комплекты карт
              </h3>
              {renderRewards(rewards[FreeRewardCategory.CardsSet])}
            </div>
            <div className="rewards__category">
              <h3 className="rewards__category-title">
                Другие награды
              </h3>
              {renderRewards(rewards[FreeRewardCategory.Other])}
            </div>            
          </div>
        </section>
      )}

    <footer>
      <div className="contacts">
        Обратная связь —{' '} 
        <a href="https://t.me/SinrVolcano" target="blank" rel="me">Telegram</a> ||{' '}
        <a href="https://vk.com/volkano" target="blank" rel="me">VK</a>
      </div>
    </footer>
    
    </div>
  );
}

export default App;
