import { Level } from '../data/levels';

export function calculateGold(level: number, levels: Level[]): number {
  // eslint-disable-next-line
  if (level > levels.length) throw {msg: 'Указанный левел выше ожидаемого'};
  let totalGold = 0;
  for(let i = 0; i < level; i++) {
    totalGold += levels[i].gold;
  }
  return totalGold;
}