import { addFreeRewards, FreeReward } from "./freeRewards";

export interface Level {
  lvl: number,
  exp: number,
  gold: number,
  freeReward?: FreeReward,
  paidRewardId?: number[] | null,
}

const initialLevels: Level[] = [
  {
    lvl: 1,
    exp: 100,
    gold: 0,
  },
  {
    lvl: 2,
    exp: 150,
    gold: 0,
  },
  {
    lvl: 3,
    exp: 200,
    gold: 0,
  },
  {
    lvl: 4,
    exp: 300,
    gold: 0,
  },
  {
    lvl: 5,
    exp: 450,
    gold: 0,
  },
  {
    lvl: 6,
    exp: 600,
    gold: 100,
  },
  {
    lvl: 7,
    exp: 750,
    gold: 100,
  },
  {
    lvl: 8,
    exp: 900,
    gold: 0,
  },
  {
    lvl: 9,
    exp: 1050,
    gold: 100,
  },
  {
    lvl: 10,
    exp: 1250,
    gold: 0,
  },
  {
    lvl: 11,
    exp: 1500,
    gold: 100,
  },
  {
    lvl: 12,
    exp: 1750,
    gold: 0,
  },
  {
    lvl: 13,
    exp: 2000,
    gold: 100,
  },
  {
    lvl: 14,
    exp: 2200,
    gold: 100,
  },
  {
    lvl: 15,
    exp: 2400,
    gold: 0,
  },
  {
    lvl: 16,
    exp: 2500,
    gold: 100,
  },
  {
    lvl: 17,
    exp: 2600,
    gold: 0,
  },
  {
    lvl: 18,
    exp: 2700,
    gold: 100,
  },
  {
    lvl: 19,
    exp: 2800,
    gold: 100,
  },
  {
    lvl: 20,
    exp: 2900,
    gold: 0,
  },
  {
    lvl: 21,
    exp: 3000,
    gold: 100,
  },
  {
    lvl: 22,
    exp: 3100,
    gold: 100,
  },
  {
    lvl: 23,
    exp: 3200,
    gold: 0,
  },
  {
    lvl: 24,
    exp: 3300,
    gold: 100,
  },
  {
    lvl: 25,
    exp: 3400,
    gold: 0,
  },
  {
    lvl: 26,
    exp: 3500,
    gold: 150,
  },
  {
    lvl: 27,
    exp: 3600,
    gold: 50,
  },
  {
    lvl: 28,
    exp: 3700,
    gold: 150,
  },
  {
    lvl: 29,
    exp: 3800,
    gold: 150,
  },
  {
    lvl: 30,
    exp: 3900,
    gold: 50,
  },
  {
    lvl: 31,
    exp: 4000,
    gold: 150,
  },
  {
    lvl: 32,
    exp: 4100,
    gold: 150,
  },
  {
    lvl: 33,
    exp: 4250,
    gold: 150,
  },
  {
    lvl: 34,
    exp: 4500,
    gold: 150,
  },
  {
    lvl: 35,
    exp: 4750,
    gold: 0,
  },
  {
    lvl: 36,
    exp: 5000,
    gold: 200,
  },
  {
    lvl: 37,
    exp: 5000,
    gold: 200,
  },
  {
    lvl: 38,
    exp: 5000,
    gold: 200,
  },
  {
    lvl: 39,
    exp: 5000,
    gold: 200,
  },
  {
    lvl: 40,
    exp: 5000,
    gold: 200,
  },
  {
    lvl: 41,
    exp: 5000,
    gold: 200,
  },
  {
    lvl: 42,
    exp: 5000,
    gold: 200,
  },
  {
    lvl: 43,
    exp: 5000,
    gold: 200,
  },
  {
    lvl: 44,
    exp: 5000,
    gold: 200,
  },
  {
    lvl: 45,
    exp: 5000,
    gold: 300,
  },
  {
    lvl: 46,
    exp: 5000,
    gold: 300,
  },
  {
    lvl: 47,
    exp: 5000,
    gold: 300,
  },
  {
    lvl: 48,
    exp: 5000,
    gold: 300,
  },
  {
    lvl: 49,
    exp: 5000,
    gold: 300,
  },
  {
    lvl: 50,
    exp: 1325,
    gold: 0,
  },
];

function addNextLevels(levels : Level[]): Level[] {
  for (let i = 51; i <= 350; i++) {
    let experience = 0;
    if (i < 53) {
      experience = 1325;
    } else if (i < 59) {
      experience = 1350;
    } else if (i < 62) {
      experience = 1375;
    } else if (i < 68) {
      experience = 1400;
    } else if (i < 71) {
      experience = 1425;
    } else if (i < 77) {
      experience = 1450;
    } else if (i < 80) {
      experience = 1475;
    } else {
      experience = 1500;
    }
    
    levels.push({
      lvl: i,
      exp: experience,
      gold: 50,   
    })
  }
  return levels;
}

export const levels = addFreeRewards(addNextLevels(initialLevels));