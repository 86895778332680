import { Level } from "../data/levels";

export function getLevel(levels: Level[], experience: number): number {
  let exp = 0;
  for(let i = 0; i < levels.length; i++) {
    exp += levels[i].exp;
    if (exp > experience) {
      return levels[i].lvl;
    }
  }
  return levels[levels.length - 1].lvl
}