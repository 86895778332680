import legendaryCard from '../images/legendary-card.png';
import darkmoonFaire from '../images/darkmoon-faire.png';
import scholomanceAcademy from '../images/scholomance-academy.png';
import ashesOfOutland from '../images/ashes-of-outland.png';
import dragonYear from '../images/year-of-the-dragon.png';
import epicСard from '../images/epic-card.png';
import lightsFair from '../images/lights-fair.png'
import tavernPass from '../images/tavern-pass.png'
import { Level } from './levels';

export interface FreeReward {
  id: number;
  name: string;
  lvls: number[];
  imgUrl: string;
  type: FreeRewardType;
  category: FreeRewardCategory
}

export enum FreeRewardCategory {
  Other = 'other',
  CardsSet = 'cardsSet',
}

export enum FreeRewardType {
  CardsSet = 'cardsSet',
  Card = 'card',
  ShirtCard = 'shirtCard',
  TavernPass = 'tavernPass'
}

export const freeRewards: FreeReward[] = [
    {
      id: 1,
      name: 'Случайная легендарная карта',
      lvls: [1, 25],
      type: FreeRewardType.Card,
      category: FreeRewardCategory.Other,
      imgUrl: legendaryCard,
    },
    {
      id: 2,
      name: 'Случайная эпическая карта',
      type: FreeRewardType.Card,
      category: FreeRewardCategory.Other,
      lvls: [5],
      imgUrl: epicСard,
    },
    {
      id: 3,
      name: 'Рубашка "Огни Ярмарки"',
      type: FreeRewardType.ShirtCard,
      category: FreeRewardCategory.Other,
      lvls: [10],
      imgUrl: lightsFair,
    }, 
    {
      id: 4,
      name: 'Пропуск в таверну',
      type: FreeRewardType.TavernPass,
      category: FreeRewardCategory.Other,
      lvls: [15, 35],
      imgUrl: tavernPass,
    },        
    {
      id: 5,
      name: 'Комплект Ярмарки безумия',
      lvls: [2, 3, 4],
      type: FreeRewardType.CardsSet,
      category: FreeRewardCategory.CardsSet,
      imgUrl: darkmoonFaire,
    },
    {
      id: 6,
      name: 'Комплект Некроситета',
      lvls: [8, 23],
      type: FreeRewardType.CardsSet,
      category: FreeRewardCategory.CardsSet,
      imgUrl: scholomanceAcademy,
    },
    {
      id: 7,
      name: 'Комплект Руин Запределья',
      lvls: [12, 27, 30],
      type: FreeRewardType.CardsSet,
      category: FreeRewardCategory.CardsSet,
      imgUrl: ashesOfOutland,
    },
    {
      id: 8,
      name: 'Комплект Года Дракона',
      lvls: [17],
      type: FreeRewardType.CardsSet,
      category: FreeRewardCategory.CardsSet,
      imgUrl: dragonYear,
    },
];

export const addFreeRewards = (levels: Level[]): Level[] => {
  freeRewards.forEach(item => {
    item.lvls.forEach(lvl => {
      levels[lvl].freeReward = item;
    })
  })
  return levels;
}