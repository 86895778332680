import { FreeRewardCategory, FreeRewardType } from "../data/freeRewards";
import { Level } from "../data/levels"
import { groupBy } from "./group-by";

export interface Reward {
  id: number;
  name: string;
  imgUrl: string;
  count: number;
  type: FreeRewardType;
  category: FreeRewardCategory;
}

export type GroupedReward = Record<FreeRewardCategory, Reward[]>

export const calculateRewards = (level: number, levels: Level[]): GroupedReward => {
  const freeRewards: Reward[] = [];
  for (let i = 0; i <= level; i++) {
    const reward = levels[i].freeReward;
    if (reward) {
      const isInclude = freeRewards.find(x => x.id === reward.id);
      if (!isInclude) {
        freeRewards.push({
          id: reward.id,
          name: reward.name,
          imgUrl: reward.imgUrl,
          count: 1,
          type: reward.type,
          category: reward.category,
        });
      } else {
        isInclude.count += 1;
      }
    }
  }
  return groupBy(freeRewards, (i) => i.category)
}